<template>
  <v-app
    class="changeColorPrint"
    :style="{
      background: $vuetify.theme.themes[theme].background,
      color: $vuetify.theme.themes[theme].color,
    }"
    :class="{
      'app-admin-wrap-layout-1 sidebar-mini': getThemeMode.verticalSidebarMini,
      'sidebar-close': !getThemeMode.verticalSidebarDrawer,
    }"
  >
    <snack-bar />
    <side-bar :me="getLocalProfile" />

    <app-bar :me="getLocalProfile" />
    <!-- <sub-header>

    </sub-header> -->
    <!-- Sizes your content based upon application components -->

    <base-view :me="getLocalProfile" />
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import UserService from '@/services/user.service'
  import SnackBar from '@/components/base/SnackBar'

  export default {
    components: {
      BaseView: () => import('./View'),
      SideBar: () => import('./Sidebar'),
      AppBar: () => import('./AppBar'),
      // SubHeader: () => import('./SubHeader'),
      SnackBar,
    },
    computed: {
      ...mapGetters(['getThemeMode']),

      theme () {
        return this.$vuetify.theme.dark ? 'dark' : 'light'
      },
      getLocalProfile () {
        return UserService.getLocalProfile()
      },
    },
  }
</script>

<style lang="scss">
@media print {
   .changeColorPrint {
      background-color: transparent !important;
   }
}
.v-tooltip__content {
  background-color: #423f3f;
}
</style>
